const missionType =
  process.env.NODE_ENV === 'development'
    ? {
      /** desenvolvimento */
      anagramaId: 'b0d14d7d-c81a-4538-8a0f-183b0f9f2fdd',
      videoQuizId: '78bc1df1-0942-4917-8d62-7c3cf004ab67',
      artigoQuizId: 'ae67b86e-915e-4d95-893a-e87c0f15b511',
      palavraCruzadaId: '3ce2cbaf-4dab-4b55-9a44-fbd49e427f61',
      textoLivreId: '60ee2f5d-4395-4579-93d6-d91c85399479',
      quizId: '3a8dbbb6-7d7e-43fa-a694-48514667155b',
    }
    : {
      /** produção */
      anagramaId: '23866dfd-1061-42cd-aa32-0b77c3030765',
      videoQuizId: '7625b3ee-e314-4a02-abb1-327ce6b0180e',
      artigoQuizId: '66dc30e0-4fbc-4058-a449-628bf0bbb4e7',
      palavraCruzadaId: '06c9a5bf-7a04-4e81-b2b9-158f0050ebac',
      textoLivreId: 'eb814960-84f7-4d3c-b437-5fa09698bfc6',
      quizId: '45928c72-3cec-4625-8b58-db1dc20f3c28',
    }

export default missionType
