const videoPlatforms =
  process.env.NODE_ENV === 'development'
    ? {
      /** desenvolvimento */
      vimeo: '496ef4ae-39c4-4a23-be38-ae48e292cd14',
      youtube: '2d6e2cca-88e8-4707-a195-531b0f57d8ea',
      interno: 'bfebebbe-5038-443c-8293-a94208753bd4',
    }
    : {
      /** produção */
      vimeo: '10fdef18-ea9c-4675-af14-37d0ab9fc50f',
      youtube: '69f7f1e7-f2ce-420e-9f03-96ccf1937c9b',
      interno: '9b0be824-d362-4bb0-804d-0c3f1b1ca3b8',
    }

export default videoPlatforms
